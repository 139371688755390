import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const mentions = props => (
  <Layout {...props}>
    <Seo title='Mentions Légales' />
    <Divider />
    <Stack>
      <Main>
        {/* <Section style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', alignContent: 'center' }}> */}
        <section style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', alignContent: 'center' }}>
        <div style={{ width: '49%', paddingLeft: '20px', borderLeft: '2px solid', marginTop: '60px', fontSize: '1rem' }}>
          <p>EURL Chloé Carmona Immobilier au capital de 1000 €</p>
          <p>Siège social : 9 rue Saint Exupéry 33460 Arsac,</p>
          <p>SIREN 917 906 778 RCS Bordeaux, </p>
          <p>représentée par sa gérante Chloé Carmona </p>
          <p>N° TVA UE :  FR53917906778. </p>
          
      </div>
      <div style={{ width: '49%', paddingLeft: '20px', borderLeft: '2px solid', marginTop: '40px', fontSize: '1rem' }}>
        <p>Ayant le titre professionnel d’Agent Immobilier obtenu en France.</p>
        <p>Carte professionnelle n° 330 120 220 00 000 122 délivrée par la CCI de Bordeaux 
        portant les mentions « transactions sur immeubles et fonds de commerce »
        RCP souscrites auprès de MMA IARD 14 boulevard Marie et Alexandre Oyon 72030 LE MANS CEDEX 9 </p>
      </div>
      </section>
        {/* </Section> */}
      </Main>
    </Stack>
  </Layout>
)

export default mentions
